<template>
  <main class="layout-hasColG">
    <header class="layer-primary">
      <div class="main-img">
        <img loading="lazy" alt="Maine-et-Loire.fr" src="@/assets/img_assmat.jpg" />
      </div>
    </header>
    
    <section role="main" class="section-content" id="section-content">
      <header>
        <ul id="site-nav-breadcrumbs" class="site-nav-breadcrumbs">
          <li><router-link :to="{name: 'accueil'}">Accueil</router-link></li>
          <li>Ma sélection</li>
        </ul>
        
        <h1>Ma sélection</h1>
      </header>
      
      <div>
        <aside class="sidebar-secondary">
          <div v-if="!noSelection" class="pensebete">
            <h2>Choisissez le format de téléchargement :</h2>
              <a @click="downloadURL('pdf')" class="btn-cta-pill-inverse" title="Télécharger la sélection en PDF"> <span class="fa fa-solid fa-file-pdf fa-2x"></span><br /><abbr title="Portable document format" lang="en">PDF</abbr></a>
              <a @click="downloadURL('csv')" class="btn-cta-pill-inverse" title="Télécharger la sélection en CSV"> <span class="fa fa-solid fa-file-csv fa-2x"></span><br /><abbr title="Comma-separated values" lang="en">CSV</abbr></a>
          </div>
        </aside>
        <article id="main-content">
          <h2>Liste des assistants maternels</h2>
          <button class="btn-cta-standard btn-destructive mr-0" v-if="!noSelection" @click="displayDialogConfirm"><span class="fa fa-solid fa-trash-alt"></span>Vider ma sélection</button>
          
          <p v-if="!noSelection">
            Vous avez
            <strong>{{ selection.length }}</strong> <span v-if="selection.length > 1">assistants maternels</span> <span v-else>assistant maternel</span>
            dans votre sélection.
          </p>

          <div v-if="noSelection">Il n'y a pas d'assistant maternel à afficher.</div>
          
          <div v-if="!noSelection" class="grid-3-small-1 grid-with-gutter">
            <cardAssmat v-for="s in selection" :assmat="s" :key="s.id"></cardAssmat>
          </div>
        </article>
      </div>
    </section>

    <div :class="dialogConfirmActive ? 'is-shown' : ''" class="dialog dialog--confirm confirm--alert">
    <div class="dialog__overlay"></div>
    <div class="dialog__container">
        <div class="dialog__background">
            <section class="dialog__content">
                <div class="confirm__icon"><span class="fa fa-solid fa-trash-alt"></span></div>
                <p class="confirm__headline">Vider ma sélection ?</p>
                <p>Vous allez effacer votre liste de fiches favorites.</p>
            </section>
            <footer class="dialog__footer">
                <a class="button btn-cta-pill btn-destructive" @click="flushSelection">Oui, je vide ma sélection</a>
                <a class="button btn-cta-pill-inverse btn-destructive-inverse" @click="closeDialog">Annuler</a>
            </footer>
        </div>
    </div>
</div>

  </main>
</template>

<script>
import { mapActions } from 'vuex';
import cardAssmat from '@/components/cardAssmat.vue';

export default {
  name: 'Selection',
  components: {
    cardAssmat
  },
  data() {
    return {
      dialogConfirmActive: false
    };
  },
  computed: {
    selection: function() {
      return this.$store.getters.assmats;
    },
    noSelection: function() {
      return !this.$store.getters.assmats.length;
    }
  },
  methods: {
    ...mapActions(['removeAllAssmats']),
    downloadURL: function (endpoint) {
      let ids = this.selection.map(function (obj) {
        return obj.id;
      });
      
      this.$http({
        method: 'post',
        url: 'export/favoris/' + endpoint,
        data: {
          idsAssmats: ids
        },
        responseType: 'blob'
      }).then((response) => {
        let url = URL.createObjectURL(new Blob([response.data])),
        a = document.createElement('a');
        a.href = url;
        a.download = 'assistants_maternels_selection.' + endpoint;
        a.click();
        a.remove();
        setTimeout(() => URL.revokeObjectURL(url), 100);
      });
    },
    displayDialogConfirm: function () {
      if (this.dialogConfirmActive) {
        this.dialogConfirmActive = false;
      } else {
        this.dialogConfirmActive = true;
      }
    },
    closeDialog: function () {
      this.dialogConfirmActive = false;
    },
    flushSelection: function() {
      this.closeDialog()
      this.removeAllAssmats()
    }
  }
};
</script>

<style>
.mr-0 {
  margin-right: 0;
}
</style>