<template>
	<div class="list-item-assmat">
		<header>
			<div v-if="assmat.dispoActuelle.aucuneDispoActuelle && !assmat.disposFutures" class="list-item-assmat-availability-no"><span class="fa fa-regular fa-calendar-times"></span>Aucune disponibilité actuellement</div>
			<div v-else-if="assmat.dispoActuelle.dispoPeriscolaire || assmat.dispoActuelle.dispoVacancesScolaires || assmat.dispoActuelle.nombreMoins1An || assmat.dispoActuelle.nombreMoins2Ans || assmat.dispoActuelle.nombreMoins3Ans" class="list-item-assmat-availability"><span class="fa fa-regular fa-calendar-check"></span>Disponible actuellement</div>
			<div v-else-if="assmat.disposFutures" class="list-item-assmat-availability-soon">
				<span class="fa fa-solid fa-calendar-day"></span>
				Disponible à partir du {{ getLongDate(assmat.disposFutures[0].dateDebut) }}
			</div>
			<div v-else class="list-item-assmat-availability-empty"><span class="fa fa-regular fa-question-circle"></span>Disponibilité non renseignée</div>

			<span v-if="showDistance" class="list-item-assmat-distance">
				À {{ getDistance(assmat.adresse.distance) }}
				<abbr v-if="assmat.adresse.distance > 1000" title="kilomètres">km</abbr>
				<abbr v-else title="mètres">m</abbr>
			</span>
		</header>
		<div>
			<p class="list-item-assmat-title">
				<router-link :to="{ name: 'assmat', params: { userId: assmat.id } }" class="list-item-assmat-link">{{ assmat.prenom }} {{ assmat.nom }}</router-link>
			</p>
			<p class="list-item-assmat-coord">
				{{ assmat.adresse.numero }} {{ assmat.adresse.libelleAdresse }}<br />
				{{ assmat.adresse.codePostal }} {{ assmat.adresse.commune }}<br />
				<span v-if="assmat.coordonnees.numeroTelephoneFixe">
					<span class="fa fa-solid fa-phone-alt"></span>
					<phoneLink :phoneNumber="assmat.coordonnees.numeroTelephoneFixe"></phoneLink><br />
				</span>
				<span v-if="assmat.coordonnees.numeroTelephonePortable">
					<span class="fa fa-solid fa-mobile-alt"></span>
					<phoneLink :phoneNumber="assmat.coordonnees.numeroTelephonePortable"></phoneLink><br />
				</span>
			</p>
		</div>
		<router-link :to="{ name: 'assmat', params: { userId: assmat.id } }" class="list-item-assmat-link">Voir la fiche</router-link>
		<button type="button" name="button" @click="selectionToggle(assmat)" class="list-item-assmat-btn-favorite" :title="getTitle(assmat)" :aria-pressed="[isSelected ? true : false]">
			<span v-if="isSelected" class="fa fa-solid fa-star" :class="{ active: isSelected }"><span class="sr-only sr-only-focusable">Retirer de</span></span>
			<span v-else class="fa fa-regular fa-star"><span class="sr-only sr-only-focusable">Ajouter à</span></span>
			<span class="sr-only sr-only-focusable">mes favoris</span>
			<span :class="{ active: isSelected }" class="list-item-assmat-btn-favorite-txt">Ajouté !</span>
		</button>
		<footer v-if="assmat.dateDerniereModification">Mise à jour le {{ getLongDate(assmat.dateDerniereModification) }}</footer>
	</div>
</template>

<script>
import phoneLink from "@/components/phoneLink.vue";
import { mapActions } from "vuex";

export default {
	name: "cardAssmat",
	props: {
		assmat: Object,
		showDistance: Boolean,
	},
	computed: {
		isSelected: function() {
			return this.$store.getters.isAssmatExists(this.assmat.id);
		},
	},
	components: {
		phoneLink,
	},
	methods: {
		...mapActions(["addAssmat", "removeAssmat"]),
		getDistance(meters) {
			let result = meters;

			if (meters > Math.pow(10, 3)) {
				result = result / Math.pow(10, 3);
			}
			return Math.trunc(result);
		},
		selectionToggle(assmat) {
			if (this.isSelected) {
				this.removeAssmat(assmat);
				this.isSelected = false;
			} else {
				this.addAssmat(assmat);
				this.isSelected = true;
			}

			this.$emit("changedCard");
		},
		getTitle(assmat) {
			if (this.isSelected) {
				return `Retirer la fiche de ${assmat.prenom} ${assmat.nom} de ma sélection`;
			} else {
				return `Ajouter la fiche de ${assmat.prenom} ${assmat.nom} à ma sélection`;
			}
		},
	},
};
</script>
