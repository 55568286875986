<template>
  <a :href="getPhoneLink(phoneNumber)" :title="getTitle(phoneNumber)">{{phoneNumber}}</a>
</template>

<script>
export default {
  name: 'phoneLink',
  props: {
    phoneNumber: String
  },
  methods: {
    getPhoneLink: function(tel) {
      let toReturn = tel.startsWith('0') ? tel.substring(1) : tel
      return "tel:+33"+toReturn.replace(/\s+/g, '')
    },
    getTitle: function(tel) {
      return `Appeler l’assistant maternel au ${tel}`
    }
  }
}
</script>